const ENABLED = true;

const noLogger = () => undefined;

export function createLogger(name: string, color: string) {
  if (!ENABLED) return noLogger;

  const prefix = `%c${name} - `;
  color = `color: ${color}`;

  return function (...args: any) {
    const [head, values] =
      typeof args[0] === "string" ? [args[0], args.slice(1)] : ["", args];
    // eslint-disable-next-line no-console
    console.log(...[prefix + head, color, ...values]);
  };
}
