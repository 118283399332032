import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  addFileToWorkspace,
  getDefaultWorkspace,
  getFileBlob,
  listFilesFromWorkspace,
  removeFileFromWorkspace,
  StoredFile,
  Workspace,
} from "lib/storage";
import invariant from "tiny-invariant";

function invalidateWorkspaceFiles(
  queryClient: QueryClient,
  workspace: Workspace | undefined
) {
  invariant(workspace, "Workspace is required for invalidating cache");
  queryClient.invalidateQueries({
    queryKey: ["files", workspace.id],
  });
}

export function useDefaultWorkspace() {
  return useQuery({
    queryKey: ["workspaces", "default"],
    queryFn: getDefaultWorkspace,
  });
}

export function useFileBlob(file?: StoredFile) {
  const query = useQuery({
    queryKey: ["fileBlob", file?.id || "<none>"],
    queryFn: () => (file ? getFileBlob(file) : Promise.reject("No file")),
    enabled: !!file,
  });

  const blob =
    query.data && file
      ? query.data.slice(0, query.data.size, file.mimeType)
      : undefined;

  return { ...query, blob };
}

export function useAddFileToWorkspaceMutation(
  workspace: Workspace | undefined,
  ffmpegCommand = ""
) {
  const queryClient = useQueryClient();

  const addFile = useMutation({
    mutationFn: async (files: File[]) => {
      invariant(workspace, "Can't add a file to an undefined workspace");
      for (const file of files) {
        await addFileToWorkspace(workspace, file, ffmpegCommand);
      }
      return true;
    },
    onSuccess: () => invalidateWorkspaceFiles(queryClient, workspace),
  });

  return addFile;
}

export function useRemoveFileFromWorkspaceMutation(
  workspace: Workspace | undefined,
  file: StoredFile | undefined
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      invariant(workspace, "Workspace is not defined");
      invariant(file, "File is not defined");
      return removeFileFromWorkspace(workspace, file);
    },
    onSuccess: () => invalidateWorkspaceFiles(queryClient, workspace),
  });
}

export function useWorkspaceFilesQuery(workspace?: Workspace) {
  return useQuery({
    queryKey: ["files", workspace?.id ?? ""],
    queryFn: () =>
      workspace
        ? listFilesFromWorkspace(workspace)
        : Promise.reject("No workspace"),
    enabled: !!workspace?.id,
  });
}
