export function getExtension(fileName: string): string {
  const lastDot = fileName.lastIndexOf(".");
  if (lastDot === -1) return "";

  return fileName.slice(lastDot + 1);
}

export function replaceExtension(fileName: string, ext: string): string {
  const lastDot = fileName.lastIndexOf(".");
  if (lastDot === -1) return fileName;

  return fileName.slice(0, lastDot + 1) + ext;
}
