import { getExtension } from "helpers/filename-helpers";
import { DBSchema, IDBPDatabase, openDB } from "idb";
import { customAlphabet } from "nanoid";
import invariant from "tiny-invariant";

const nanoid = customAlphabet("1234567890abcdef", 12);

type StoredBlob = {
  id: string;
  blob: Blob;
};

export type StoredFile = {
  id: string;
  workspaceId: string;
  blobId: string;
  bytes: number;
  mimeType: string;
  name: string;
  ext: string;
  parentFileId?: string;
  presetName?: string;
};

export type Workspace = {
  id: string;
  name: string;
};

interface WavelabIndexDb extends DBSchema {
  blobs: {
    key: string;
    value: StoredBlob;
  };
  files: {
    key: string;
    value: StoredFile;
    indexes: { "by-workspace": string };
  };
  workspaces: {
    key: string;
    value: Workspace;
  };
}

let _db: IDBPDatabase<WavelabIndexDb>;

async function getDatabase() {
  _db ??= await openDB<WavelabIndexDb>("wavelab", 1, {
    upgrade(db) {
      db.createObjectStore("blobs", {
        keyPath: "id",
      });
      const filesStore = db.createObjectStore("files", {
        keyPath: "id",
      });
      filesStore.createIndex("by-workspace", "workspaceId");
      db.createObjectStore("workspaces", {
        keyPath: "id",
      });
    },
  });
  return _db;
}

export async function getDefaultWorkspace() {
  const db = await getDatabase();
  const workspace = await db.get("workspaces", "default");
  if (workspace) return workspace;

  await db.put("workspaces", { id: "default", name: "Default" });
  const created = await db.get("workspaces", "default");
  invariant(created !== undefined);
  return created;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function listWorkspaces() {
  const db = await getDatabase();
  return db.getAll("workspaces");
}

export async function listFilesFromWorkspace(
  workspace: Workspace
): Promise<StoredFile[]> {
  const db = await getDatabase();
  return db.getAllFromIndex("files", "by-workspace", workspace.id);
}

export async function removeFileFromWorkspace(
  workspace: Workspace,
  file: StoredFile
): Promise<boolean> {
  const db = await getDatabase();

  await db.delete("blobs", file.blobId);
  await db.delete("files", file.id);
  return true;
}

export async function addFileToWorkspace(
  workspace: Workspace,
  file: File,
  parentFileId = "",
  presetName = ""
): Promise<StoredFile> {
  const ext = getExtension(file.name);
  const blob: StoredBlob = {
    id: nanoid(),
    blob: file,
  };
  const stored: StoredFile = {
    id: nanoid(),
    blobId: blob.id,
    workspaceId: workspace.id,
    bytes: file.size,
    name: file.name,
    ext,
    mimeType: file.type,
    parentFileId,
    presetName,
  };

  const db = await getDatabase();
  await db.put("blobs", blob);
  await db.put("files", stored);
  return stored;
}

export async function getFileBlob(file: StoredFile): Promise<Blob | undefined> {
  const db = await getDatabase();
  const record = await db.get("blobs", file.blobId);
  return record?.blob;
}
