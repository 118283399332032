import { replaceExtension } from "helpers/filename-helpers";
import { getFFmpeg } from "lib/ffmpeg";
import {
  addFileToWorkspace,
  getFileBlob,
  StoredFile,
  Workspace,
} from "lib/storage";
import invariant from "tiny-invariant";

// See https://github.com/peterforgacs/ffmpeg-normalize#loudness-normalization
type MeasureLoudnessOptions = {
  /**
   * The normalization target level in dB/LUFS.
   * Min: -70.0
   * Max: -5.0
   */
  input_i: number;

  /**
   * Loudness range.
   * Min: 1.0
   * Max: 20.0
   */
  input_lra: number;

  /**
   * loudness input_tp
   * Min: -9.0
   * Max: 0.0
   */
  input_tp: number;
};

type MeasuredLoudness = {
  input_i: number;
  input_lra: number;
  input_tp: number;
  target_offset: number;
  input_thresh: number;
};

export async function ffmpegNormalizeLoudness(
  workspace: Workspace,
  file: StoredFile,
  options?: MeasureLoudnessOptions
) {
  const blob = await getFileBlob(file);
  invariant(blob, `Can't get blob from file ${file.id}`);
  // Defaults based on: https://productionadvice.co.uk/spotify-reduced-loudness/
  options = { ...options, input_i: -14, input_lra: 7, input_tp: -1 };
  const measured = await measureLoudness(blob, file.name, "audio.wav", options);
  console.log(">>>>", { measured });
  const outputFileName = replaceExtension(file.name, "ogg");

  await normalizeLoudness("audio.wav", outputFileName, options, measured);
  const ffmpeg = await getFFmpeg();
  const outputFile = ffmpeg.readFile(outputFileName, "audio/ogg");
  await addFileToWorkspace(
    workspace,
    outputFile,
    file.id,
    "loudness:normalize"
  );
}

async function measureLoudness(
  blob: Blob,
  inputFileName: string,
  outputFileName: string,
  options: MeasureLoudnessOptions
): Promise<MeasuredLoudness> {
  const ffmpeg = await getFFmpeg();
  await ffmpeg.writeFile(inputFileName, blob);

  const loudnorm =
    "loudnorm=" +
    `I=${options.input_i}:` +
    `LRA=${options.input_lra}:` +
    `tp=${options.input_tp}:` +
    `print_format=json`;

  const output = await ffmpeg.run([
    "-i",
    inputFileName,
    "-af",
    loudnorm,
    "-c:a",
    "pcm_f32le",
    outputFileName,
  ]);

  const params: Record<string, number> = {};
  for (const [_, line] of output) {
    const param = getParameter(line);
    if (param) {
      params[param.name] = param.value;
    }
  }

  return params as MeasuredLoudness;
}

function getParameter(
  line: string
): { name: string; value: number } | undefined {
  const parameters = [
    "input_i",
    "input_tp",
    "input_lra",
    "input_thresh",
    "output_i",
    "output_tp",
    "output_lra",
    "output_thresh",
    "target_offset",
  ];

  for (const paramName of parameters) {
    if (line.indexOf(paramName) !== -1) {
      const value = +line.replace(/[^-.\d]/g, "");
      return { name: paramName, value: value };
    }
  }
  return;
}

async function normalizeLoudness(
  inputFileName: string,
  outputFileName: string,
  options: MeasureLoudnessOptions,
  measured: MeasuredLoudness
) {
  const ffmpeg = await getFFmpeg();
  ffmpeg.ffmpegDurationHandler = (duration) => {
    console.log(`Audio duration ${duration} seconds`);
  };
  ffmpeg.ffmpegProgressHandler = (progress) => {
    console.log(`Transcoding progress ${progress}%`);
  };

  // See https://wiki.tnonline.net/w/Blog/Audio_normalization_with_FFmpeg
  const loudnorm =
    "loudnorm=" +
    `I=${options.input_i}` +
    `LRA=${options.input_lra}` +
    `tp=${options.input_tp}` +
    `measured_I=${measured.input_i}` +
    `measured_LRA=${measured.input_lra}` +
    `measured_tp=${measured.input_tp}` +
    `measured_thresh=${measured.input_thresh}` +
    `offset=${measured.target_offset}`;

  await ffmpeg.run([
    "-i",
    inputFileName,
    "-af",
    loudnorm,
    "libopus",
    outputFileName,
  ]);
  console.log("NORMALIZED");
  // TODO: how to read file
  // See: https://github.com/JorenSix/ffmpeg.audio.wasm/blob/master/examples/browser/transcode.html#L186
  // and: https://github.com/JorenSix/ffmpeg.audio.wasm/blob/master/examples/node/transcode.js#L19
  // const blobUrl = URL.createObjectURL(task.output_file.asBlob());
}
