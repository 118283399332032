/* eslint-disable import/no-unused-modules */
// ffmpeg -i 0801035.mp3 -af loudnorm=I=-23:LRA=7:tp=-2:print_format=json -f null -

import { replaceExtension } from "helpers/filename-helpers";
import { getFFmpeg } from "lib/ffmpeg";
import { addFileToWorkspace, StoredFile, Workspace } from "lib/storage";

export async function ffmpegTranscodeAudio(
  blob: Blob,
  file: StoredFile,
  workspace: Workspace
) {
  const ffmpeg = await getFFmpeg();
  await ffmpeg.writeFile(file.name, blob);
  ffmpeg.ffmpegDurationHandler = (duration) => {
    console.log(`Audio duration ${duration} seconds`);
  };
  ffmpeg.ffmpegProgressHandler = (progress) => {
    console.log(`Transcoding progress ${progress}%`);
  };
  const outputFileName = replaceExtension(file.name, "ogg");
  await ffmpeg.run(["-i", file.name, "-c:a", "libopus", outputFileName]);
  console.log("TRANSCODE ENDED!!");
  console.log(ffmpeg.ffmpegCore?.FS.readdir("."));
  const buffer = ffmpeg.ffmpegCore?.FS.readFile(outputFileName);
  if (!buffer) return;

  const outputFile = new File(
    [new Blob([buffer], { type: "audio/ogg" })],
    outputFileName,
    { type: "audio/ogg" }
  );
  await addFileToWorkspace(workspace, outputFile, file.id, "export:ogg");
  console.log("File added");
}
