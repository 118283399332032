import { ReactNode } from "react";
import { useDropzone } from "react-dropzone";

// eslint-disable-next-line import/no-unused-modules

export function FilesInput({
  className,
  maxFiles,
  children,
  onSubmit,
}: {
  className?: string;
  maxFiles?: number;
  children: ReactNode;
  onSubmit: (files: File[]) => void;
}) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onSubmit,
    accept: {
      "audio/*": [".mp4", ".mp3", ".aac", ".ogg", ".wav"],
    },
    maxFiles: maxFiles,
  });

  return (
    <button {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      {children}
    </button>
  );
}
