import invariant from "tiny-invariant";
import { createLogger } from "./debug";

const log = createLogger("[ffmpeg]", "red");

type FFmpegCore = {
  callMain(args: string[]): unknown;
  FS: {
    writeFile(fileName: string, contents: Uint8Array): void;
    readdir(path: string): string[];
    readFile(fileName: string): Uint8Array;
  };
};

type FFmpegCoreFactory = {
  createFFmpegCore(params: {
    print: (text: string) => void;
    printErr: (text: string) => void;
  }): Promise<FFmpegCore>;
};

// Output format: [type, message][]
type Output = [string, string][];

/**
 * @see https://github.com/JorenSix/ffmpeg.audio.wasm/blob/master/js/ffmpeg.helper.js
 */
class FFmpegHelper {
  ffmpegCore: FFmpegCore | null;
  ffmpegCurrentDuration: number | null;
  ffmpegDurationHandler: ((duration: number) => unknown) | null;
  ffmpegLogEmptyCount: number | null;
  ffmpegProgressHandler: ((duration: number) => unknown) | null;
  ffmpegRunning: boolean;
  runResolve: ((output: Output) => void) | null;
  output: Output;

  constructor(private readonly factory: FFmpegCoreFactory) {
    this.ffmpegCore = null;
    this.ffmpegCurrentDuration = null;
    this.ffmpegDurationHandler = null;
    this.ffmpegLogEmptyCount = null;
    this.ffmpegProgressHandler = null;
    this.ffmpegRunning = false;
    this.runResolve = null;
    this.output = [];
  }

  async init() {
    this.ffmpegCore = await this.factory.createFFmpegCore({
      print: (message) => this.handleFFmpegOutput("stdout", message),
      printErr: (message) => this.handleFFmpegOutput("stderr", message),
    });
    log("ready");
  }
  run(args: string[]) {
    invariant(this.ffmpegCore, "ffmpeg not initialized");
    invariant(!this.ffmpegRunning, "ffmpeg can only run one command at a time");

    const defaultArgs = [
      "-y",
      // "-hide_banner",
      "-stats_period",
      "0.2",
      "-loglevel",
      "debug",
      "-nostdin",
    ];
    args = [...defaultArgs, ...args];

    this.ffmpegRunning = true;
    this.output = [];
    return new Promise<Output>((resolve) => {
      invariant(this.ffmpegCore);
      this.runResolve = resolve;
      console.log(">> RUN COMMAND", args.join("' '"));
      const result = this.ffmpegCore.callMain(args);
      console.log("STARTED", result);
    });
  }

  readFile(fileName: string, mimeType: string): File {
    invariant(this.ffmpegCore, "ffmpeg not initialized");
    const allFiles = this.ffmpegCore.FS.readdir(".");
    console.log({ allFiles });
    const found = allFiles.find((name) => fileName === name);
    invariant(found, `Expected file: ${fileName} not found.`);
    const buffer = this.ffmpegCore.FS.readFile(fileName);
    return new File([new Blob([buffer], { type: mimeType })], fileName, {
      type: mimeType,
    });
  }

  async writeFile(fileName: string, blob: Blob) {
    invariant(this.ffmpegCore, "ffmpeg not initialized");
    const buffer = await blob.arrayBuffer();
    this.ffmpegCore?.FS.writeFile(fileName, new Uint8Array(buffer));
  }

  private handleFFmpegOutput(type: string, message: string) {
    log("Ffmpeg output", type, message);
    // Ignore progress messages
    if (!message.startsWith("size=")) {
      this.output.push([type, message]);
    }

    this.detectDuration(message);
    this.detectProgress(message);
    this.detectCompletion(message);
  }

  private detectDuration(message: string): number | undefined {
    const duration_matches = message.match(
      /.*Duration..(\d\d).(\d\d).(\d\d).(\d+).*/m
    );
    if (duration_matches == null) return;

    const hours = parseFloat(duration_matches[1]);
    const minutes = parseFloat(duration_matches[2]);
    const seconds = parseFloat(duration_matches[3]);
    const milliseconds = parseFloat("0." + duration_matches[4]) * 1000.0;
    const duration_in_seconds =
      hours * 3600 + minutes * 60 + seconds + milliseconds / 1000.0;
    log("[ffmpeg] Duration in seconds: ", duration_in_seconds);

    if (this.ffmpegDurationHandler != null)
      this.ffmpegDurationHandler(duration_in_seconds);

    this.ffmpegCurrentDuration = duration_in_seconds;
    return duration_in_seconds;
  }

  private detectProgress(message: string): number | undefined {
    const progress_matches = message.match(
      /.*time.(\d\d).(\d\d).(\d\d).(\d+).*/m
    );

    if (progress_matches == null) return;

    const hours = parseFloat(progress_matches[1]);
    const minutes = parseFloat(progress_matches[2]);
    const seconds = parseFloat(progress_matches[3]);
    const milliseconds = parseFloat("0." + progress_matches[4]) * 1000.0;
    const progress_in_seconds =
      hours * 3600 + minutes * 60 + seconds + milliseconds / 1000.0;
    //log("Progress time in seconds: ", progress_in_seconds);

    let ratio = progress_in_seconds;
    if (this.ffmpegCurrentDuration != null)
      ratio = ~~((progress_in_seconds / this.ffmpegCurrentDuration) * 100.0);

    if (this.ffmpegProgressHandler != null) this.ffmpegProgressHandler(ratio);
    return progress_in_seconds;
  }

  private detectCompletion(message: string) {
    log("Detect completion...");
    if (
      (message.includes("kB muxing overhead") ||
        message.includes("Invalid argument") ||
        message.includes("Invalid data found") ||
        message.includes("At least one output file")) &&
      this.runResolve !== null
    ) {
      log("Completed!");

      // Wait 0.5 seconds to ensure all output is written
      const resolveFn = this.runResolve;
      setTimeout(() => {
        const output = this.output;
        this.output = [];
        resolveFn(output);
      }, 500);
      this.runResolve = null;
      this.ffmpegLogEmptyCount = 0;
      this.ffmpegRunning = false;
      //reset duration and report final progress: 100%
      this.ffmpegCurrentDuration = null;
      if (this.ffmpegProgressHandler !== null) this.ffmpegProgressHandler(100);
    }
  }
}

let instance: FFmpegHelper;

async function createInstance(): Promise<FFmpegHelper> {
  const factory = window as unknown as FFmpegCoreFactory;
  invariant(
    typeof factory.createFFmpegCore === "function",
    "Can't load FFmpeg module"
  );

  instance = new FFmpegHelper(factory);
  await instance.init();
  return instance;
}

export async function getFFmpeg(): Promise<FFmpegHelper> {
  instance ??= await createInstance();
  return instance;
}
