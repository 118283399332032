import { FilesInput } from "components/FilesInput";
import { StoredFile, Workspace } from "lib/storage";
import { useMemo, useState } from "react";
import { ffmpegNormalizeLoudness } from "services/ffmpeg-normalize-loudness";
import { ffmpegTranscodeAudio } from "services/ffmpeg-transcode-audio";
import invariant from "tiny-invariant";
import {
  useAddFileToWorkspaceMutation,
  useDefaultWorkspace,
  useFileBlob,
  useRemoveFileFromWorkspaceMutation,
  useWorkspaceFilesQuery,
} from "./hooks/use-storage";

export function App() {
  const [file, setFile] = useState<StoredFile | undefined>();

  const { data: workspace } = useDefaultWorkspace();
  return (
    <div className="w-full h-screen bg-base-300 flex">
      <div className="flex h-full p-2 bg-base-100 min-w-[12rem]">
        <Sidebar workspace={workspace} onSelectFile={setFile} />
      </div>
      <div className="flex-grow h-full px-4 py-2">
        {workspace && (
          <Main workspace={workspace} file={file} onSelectFile={setFile} />
        )}
      </div>
    </div>
  );
}

function Main({
  workspace,
  file,
  onSelectFile,
}: {
  workspace: Workspace;
  file?: StoredFile;
  onSelectFile: (file?: StoredFile) => void;
}) {
  const { blob } = useFileBlob(file);
  const removeFile = useRemoveFileFromWorkspaceMutation(workspace, file);

  const url = useMemo(() => {
    if (!blob) return;

    return URL.createObjectURL(blob);
  }, [blob]);

  return file ? (
    <div className="">
      <h1 className="text-accent">{file.name}</h1>
      <div className="flex gap-2">
        <button
          className="btn btn-sm"
          onClick={async () => {
            if (workspace && file)
              await ffmpegNormalizeLoudness(workspace, file);
          }}
        >
          Normalize
        </button>

        <button
          className="btn btn-sm"
          onClick={async () => {
            invariant(blob, "No blob to process");

            await ffmpegTranscodeAudio(blob, file, workspace);
          }}
        >
          Convert to OGG
        </button>
        <button
          className="btn btn-warning btn-sm"
          onClick={async () => {
            await removeFile.mutate();
            onSelectFile(undefined);
          }}
        >
          Delete
        </button>
      </div>
      <pre>{JSON.stringify(file, null, 2)}</pre>
      {url ? (
        <audio src={url} controls>
          <track kind="captions" />
        </audio>
      ) : null}
    </div>
  ) : (
    <div>
      <h1>{workspace.name}</h1>
    </div>
  );
}
function Sidebar({
  workspace,
  onSelectFile,
}: {
  workspace?: Workspace;
  onSelectFile: (file?: StoredFile) => void;
}) {
  const addFile = useAddFileToWorkspaceMutation(workspace);
  const { data: files } = useWorkspaceFilesQuery(workspace);
  return (
    <div className="flex flex-col">
      <h1 className="text-secondary">Wavelab</h1>
      <h2>
        <button onClick={() => onSelectFile()}>{workspace?.name}</button>
      </h2>
      <div className="flex my-4">
        <FilesInput
          className="btn btn-sm btn-secondary"
          onSubmit={addFile.mutate}
        >
          Add files
        </FilesInput>
      </div>
      <ul className="max-w-[12rem]">
        {files?.map((file) => (
          <li className="overflow-hidden" key={file.id}>
            <button
              className="p-1 text-left w-full hover:bg-base-300"
              onClick={() => {
                return onSelectFile(file);
              }}
            >
              {file.name || file.id}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
